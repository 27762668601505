@import-normalize;
@import 'assets/styles/common.scss';

.am-toast > span{
  max-width: 60%;
}

/**
 * iphone x兼容
 */
 body {
  --origin-safe-area-inset-top: 0px;
  --origin-safe-area-inset-bottom: 0px;

  --safe-area-inset-top: var(--device-safe-area-inset-top, var(--origin-safe-area-inset-top));
  --safe-area-inset-bottom: var(
    --device-safe-area-inset-bottom,
    var(--origin-safe-area-inset-bottom)
  );
  --safe-area-inset-left: 0;
  --safe-area-inset-right: 0;
}
/* 兼容 iOS < 11.2 */
@supports (width: constant(safe-area-inset-top)) {
  body {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
    --safe-area-inset-right: constant(safe-area-inset-right);
  }
}

/* 兼容 iOS >= 11.2 */
@supports (width: env(safe-area-inset-top)) {
  body {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
  }
}