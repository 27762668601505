@import "../../assets/styles/common.scss";
.main {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  @include flex-main;
  @include flex-y-c;
  @include flex-x-c;
  @include flex-main-y;
  > img {
    width: rem(80);
    height: rem(70);
    margin: rem(30) auto;
    display: block;
  }
  > p {
    color: #d5b65e;
    font-size: 0.36rem;
    font-weight: 500;
    padding: 0 0.4rem 0.4rem;
  }
  > span {
    font-size: 0.28rem;
    color: #fff;
    width: 3.52rem;
    height: 0.72rem;
    background-color: #d5b65e;
    display: block;
    text-align: center;
    line-height: 0.72rem;
    font-weight: 500;
    border-radius: 4px;
  }
}
