@mixin flex-main {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-main-y() {
  flex-direction: column;
  -webkit-flex-direction: column;
}

@mixin flex-x-c {
  -webkit-box-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@mixin flex-y-c {
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
}

@mixin flex-y-start {
  align-items: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
}

@mixin flex-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -moz-justify-content: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

@mixin flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@mixin flex-left-right {
  justify-content: space-between;
}

@mixin flex-auto-width {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}


// 生成 绝对1px 的边框 $radius 为实际测量的 圆角度两倍
@mixin set1pxBorder ($radius, $borderColor) {
  position: relative;
  &:after {
    content: "";
    border-radius: $radius;
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    transform-origin: 0 0;
    transform: scale(0.5);
    box-sizing: border-box;
    border: 1px solid $borderColor;
  }
}


// 生成 绝对Npx 的边框 $radius 为实际测量的 圆角度两倍
@mixin setNpxBorder($N, $radius, $borderColor) {
  position: relative;
  &:after {
    content: "";
    border-radius: $radius;
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    transform-origin: 0 0;
    transform: scale(0.5);
    box-sizing: border-box;
    border: $N solid $borderColor;
  }
}

@mixin setBottomLine($color: #D8D8D8, $left: 0, $right: 0) {
  position: relative;
  &::after {
    content: " ";
    position: absolute;
    left: $left;
    bottom: 0;
    right: $right;
    height: 1px;
    border-bottom: 1px solid $color;
    color: $color;
    transform-origin: 0 100%;
    transform: scaleY(0.5);
  }
}


@mixin clearfix() {
  *zoom: 1;
  &:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }
}

@mixin textOverflow($line) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
}
