@import '../function/mixin_function.scss';

.none,
.hide {
	display: none;
}
.flex {
	@include flex-main;
}

.flex-y {
	@include flex-main;
	@include flex-main-y;
}

.f-x-end{
  justify-content: flex-end;
}

.flex-w {
	flex-wrap: wrap;
}

.f-x-c {
	@include flex-x-c;
}

.f-y-c {
	@include flex-y-c;
}

.f-sb {
	@include flex-left-right;
}

.f-y-end{
  align-items: flex-end;
}

.f-s-c{
  align-self: center;
}

.f-s-end{
  align-self: flex-end;
}

.f-auto {
	@include flex-auto-width;
}

.f-start {
	@include flex-start;
}

.f-end {
	@include flex-end;
}
